import React from 'react'
import { Loading } from '../../Loading'
import { RowSinData } from '../../RowSinData'
import { RowEstablecimiento } from './RowEstablecimiento'

export const TablaEstablecimientos = ({establecimientos}) => {
    if(!establecimientos) return <Loading />
    return (
        <div className='card'>
            <div className='card-body'>
                <h6>Tabla Establecimientos</h6>
                <table className='table table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Rut</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">RBD</th>
                            <th scope="col">Razon Social</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Año Escolar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {establecimientos.length > 0 
                            ? establecimientos.map((e, index)=>(
                                <RowEstablecimiento 
                                    key={e._id}
                                    establecimiento={e}
                                    index={index + 1}
                                />
                            ))
                            : <RowSinData />

                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
