import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import { libroDigitalTablaTalleres } from '../../../action/router';
import { crearTaller } from '../../../action/talleres';
import { useForm } from '../../../hooks/useForm';

export const FormTallerNuevo = ({cursoActivo, periodoEscolarActivo, docentes}) => {
    const dispatch = useDispatch();
    const [taller, handleInputChange] = useForm({
        nombre:'',
        cantidadHoras:'',
        docente:'',
        curso: cursoActivo,
        periodoEscolar: periodoEscolarActivo
    });

    const { nombre, cantidadHoras, docente } = taller;

    const handleCrear = (e) => {
        e.preventDefault()
        if( nombre === '' || cantidadHoras==='' || docente===''  ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearTaller(taller));
        dispatch(libroDigitalTablaTalleres())
    }
    const handleCancelar = (e) => {
        e.preventDefault()
        dispatch(libroDigitalTablaTalleres())
    }
    
    return (
        <form className='card'>
            <div className='card-body'>
                <h5>Formulario ingreso taller</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="nombre" className="col-2 col-form-label">Nombre</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="nombre"
                            name="nombre"
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <label htmlFor="cantidadHoras" className="col-2 col-form-label">Cantidad de horas</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="cantidadHoras"
                            name="cantidadHoras"
                            value={ cantidadHoras }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="docente" className="col-2 col-form-label">Docente</label>
                    <select 
                        className="form-select" 
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={docente}  
                        name="docente" 
                    >
                        <option value="" defaultValue>Seleccione...</option>
                        {docentes && docentes.map(e=>(
                            <option value={e._id} key={e._id} >{e.nombres} {e.apellidoPaterno} {e.apellidoMaterno}</option>
                        ))}
                        
                    </select>
                </div>
                <div className="row mb-3 mt-3">
                    <div className="col-4">
                        <button 
                            type="submit" 
                            className="btn btn-primary" 
                            onClick={ handleCrear }
                        >Crear</button>
                    </div>
                    <div className="col-4">
                        <button 
                            type="submit" 
                            className="btn btn-danger" 
                            onClick={ handleCancelar }
                        >Cancelar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
