import React from 'react'
import { useDispatch } from 'react-redux'
import { actualizarPeriodoEscolar } from '../../../action/periodoEscolar';
import { libroDigitalTablaPeriodosEscolares } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormPeriodoEscolarActualizar = ({ periodoEscolarActivo, periodosEscolares }) => {
    const dispatch = useDispatch();
    const periodoPrincipal = periodosEscolares.find( e=> e._id === periodoEscolarActivo );
    const [periodoActivo, handleInputChange] = useForm(periodoPrincipal);
    const {periodo} = periodoActivo;

    const handleActualizar = (e) =>{
        e.preventDefault()
        dispatch(actualizarPeriodoEscolar(periodoActivo));
        dispatch(actualizarPeriodoEscolar(''));
        dispatch(libroDigitalTablaPeriodosEscolares());
    }

    const handleCancelar = (e) =>{
        e.preventDefault()
        dispatch(actualizarPeriodoEscolar(''));
        dispatch(libroDigitalTablaPeriodosEscolares());
    }
    
    return (
        <div className='card'>
            <div className='card-body'>
                <h5>Formulario actualizar periodo escolar</h5>
                <form>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="periodo" className="col-2 col-form-label">Periodo</label>
                        <div className="col-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="periodo"
                                name="periodo"
                                value={ periodo }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-primary" 
                                onClick={ handleActualizar }
                            >Actualizar</button>
                        </div>
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-danger" 
                                onClick={ handleCancelar }
                            >Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
  )
}
