import React from 'react'
import { useDispatch } from 'react-redux';
import { activarAnioEscolar, actualizarAnioEscolar } from '../../../action/anioEscolar';
import { libroDigitalFormAnioEscolarActualizar, libroDigitalTablaCursos, libroDigitalTablaPeriodosEscolares } from '../../../action/router';

export const RowAnioEscolar = ({anioEscolar, index, establecimiento }) => {
  const dispatch = useDispatch()
  const { estado } = anioEscolar;

  const handleActivar = () => {
    anioEscolar.estado = 'true';
    dispatch(actualizarAnioEscolar(anioEscolar));
  }

  const handleDesactivar = () => {
    anioEscolar.estado = 'false';
    dispatch(actualizarAnioEscolar(anioEscolar));
  }

  const handleCursos = () => {
    dispatch(activarAnioEscolar(anioEscolar._id))
    dispatch(libroDigitalTablaCursos());
  }
  
  const handlePeriodos = () => {
    dispatch(activarAnioEscolar(anioEscolar._id))
    dispatch(libroDigitalTablaPeriodosEscolares());
  }

  const handleActualizar = () => {
    dispatch(activarAnioEscolar(anioEscolar._id))
    dispatch(libroDigitalFormAnioEscolarActualizar());
  }
  

  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{anioEscolar.anioEscolar}</td>
      <td>{establecimiento.nombre}</td>
      <td>{estado 
        ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' onClick={handleDesactivar} >Activo</button></div>
        : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' onClick={handleActivar} >Inactivo</button></div>
      }
      </td>
      <td><div className='d-grid gap-2'><button className='btn btn-primary btn-sm' onClick={handleCursos} >Cursos</button></div></td>
      <td><div className='d-grid gap-2'><button className='btn btn-primary btn-sm' onClick={handlePeriodos} >Periodos</button></div></td>
      <td><div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={handleActualizar} >Modificar</button></div></td>
    </tr>
  )
}
