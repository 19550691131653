import React from 'react'
import { useDispatch } from 'react-redux';
import { libroDigitalFormTallerActualizar } from '../../../action/router';
import { activarTaller, actualizarTaller } from '../../../action/talleres';

export const RowTaller = ({taller, cursos, periodosEscolares, docentes, index}) => {
  const dispatch = useDispatch();
  const { nombre, estado , cantidadHoras, docente, curso, periodoEscolar } = taller;
  const cursoPrincipal = cursos.find(e=> e._id === curso);
  const periodoPrincipal = periodosEscolares.find(e=> e._id === periodoEscolar);
  const docentePrincipal = docentes.find(e=> e._id === docente);


  const handleActivar = () => {
    taller.estado=true;
    dispatch(actualizarTaller(taller))
  }

  const handleDesactivar = () => {
    taller.estado=false;
    dispatch(actualizarTaller(taller))
  }
  

  const handleActualizar = () => {
    dispatch(activarTaller(taller._id))
    dispatch(libroDigitalFormTallerActualizar())
  }


  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{nombre}</td>
      <td>{cantidadHoras}</td>
      <td>{docentePrincipal.nombres} {docentePrincipal.apellidoPaterno} {docentePrincipal.apellidoMaterno}</td>
      <td>{cursoPrincipal.nombre}</td>
      <td>{periodoPrincipal.periodo}</td>
      <td>
        {estado 
          ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' onClick={handleDesactivar} >Activo</button></div>
          : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' onClick={handleActivar} >Inactivo</button></div>
        }
      </td>
      <td><div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={handleActualizar} >Modificar</button></div></td>
    </tr>
  )
}