import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { activarCurso, actualizarCurso } from '../../../action/curso'
import { libroDigitalTablaCursos } from '../../../action/router'
import { useForm } from '../../../hooks/useForm'

export const FormCursoActualizar = ({cursoActivo, cursos, docentes}) => {
    const dispatch=useDispatch()
    const cursoPrincipal = cursos.find(e=>e._id===cursoActivo)
    const [curso, handleInputChange] = useForm(cursoPrincipal)
    const { nombre, docente } = curso;
    
    
    const handleActualizar = (e) => {
        e.preventDefault()
        if( nombre === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(actualizarCurso(curso));
        dispatch(activarCurso(""))
        dispatch(libroDigitalTablaCursos())
    }
    const handleCancelar = (e) => {
        e.preventDefault()
        dispatch(activarCurso(""))
        dispatch(libroDigitalTablaCursos())
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <h5>Formulario actualizar curso</h5>
                <form>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="nombre" className="col-2 col-form-label">Nombre</label>
                        <div className="col-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="nombre"
                                name="nombre"
                                value={ nombre }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="docente" className="col-2 col-form-label">Docente</label>
                        <select 
                            className="form-select" 
                            aria-label="Default select example"
                            onChange={handleInputChange}
                            value={docente}  
                            name="docente" 
                        >
                            <option value="" defaultValue>Seleccione...</option>
                            {docentes && docentes.map(e=>(
                                <option value={e._id} key={e._id}  >{e.nombres} {e.apellidoPaterno} {e.apellidoMaterno}</option>
                            ))}
                        </select>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className='col-12'>
                            <div className='row'>
                                <div className="col-3">
                                    <button 
                                        type="submit" 
                                        className="btn btn-primary" 
                                        onClick={ handleActualizar }
                                    >Actualizar</button>
                                </div>
                                <div className="col-3">
                                    <button 
                                        type="submit" 
                                        className="btn btn-danger" 
                                        onClick={ handleCancelar }
                                    >Cancelar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
                
    )
}
