import React from 'react'
import { useDispatch } from 'react-redux'
import { limpiarAsignaturas } from '../../../action/asignatura'
import { activarPeriodoEscolar } from '../../../action/periodoEscolar'
import { libroDigitalTablaPeriodosCurso } from '../../../action/router'
import { RowSinData } from '../../RowSinData'
import { RowAsignaturas } from './RowAsignaturas'

export const TablaAsignaturas = ({ asignaturas, cursos, periodosEscolares, docentes }) => {
  const dispatch=useDispatch();

  const handleRegresar = () => {
    dispatch(activarPeriodoEscolar(''));
    dispatch(limpiarAsignaturas())
    dispatch(libroDigitalTablaPeriodosCurso())
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <h6>Tabla Asignaturas</h6>
        <table className='table table-bordered table-hover'>
            <thead>
              <tr>
                <th scope="col">N°</th>
                <th scope="col">Nombre</th>
                <th scope="col">C. de Horas</th>
                <th scope="col">Docente</th>
                <th scope="col">Curso</th>
                <th scope="col">Periodo Escolar</th>
                <th scope="col">Estado</th>
                <th scope="col">Modificar</th>
              </tr>
            </thead>
            <tbody>
              {asignaturas.length > 0 
                ? asignaturas.map((e, index) =>(
                  <RowAsignaturas
                    key={e._id}
                    asignatura={e}
                    cursos={cursos}
                    periodosEscolares={periodosEscolares}
                    docentes={docentes}
                    index={index + 1}
                  />
                ))
                : <RowSinData />
              }
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className='d-grid gap-2'>
                    <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                  </div>
                </td>
              </tr>
            </tfoot>
        </table>
      </div>
    </div>
  )
}
