import React from 'react'
import { useDispatch } from 'react-redux';
import { activarPeriodoEscolar } from '../../../action/periodoEscolar';
import { libroDigitalTablaPeriodosCurso } from '../../../action/router';
import { limpiarTalleres } from '../../../action/talleres';
import { RowTaller } from './RowTaller'

export const TablaTalleres = ({talleres, cursos, periodosEscolares, docentes}) => {
  const dispatch = useDispatch();
  const handleRegresar = () => {
    dispatch(activarPeriodoEscolar(''));
    dispatch(limpiarTalleres())
    dispatch(libroDigitalTablaPeriodosCurso())
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <h6>Tabla Talleres</h6>
        <table className='table table-bordered table-hover'>
            <thead>
              <tr>
                <th scope="col">N°</th>
                <th scope="col">Nombre</th>
                <th scope="col">C. de Horas</th>
                <th scope="col">Docente</th>
                <th scope="col">Curso</th>
                <th scope="col">Periodo Escolar</th>
                <th scope="col">Estado</th>
                <th scope="col">Modificar</th>
              </tr>
            </thead>
            <tbody>
              {talleres.length > 0 && talleres.map((e, index) =>(
                <RowTaller
                  key={e._id}
                  taller={e}
                  cursos={cursos}
                  periodosEscolares={periodosEscolares}
                  docentes={docentes}
                  index={index + 1}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className='d-grid gap-2'>
                    <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                  </div>
                </td>
              </tr>
            </tfoot>
        </table>
      </div>
    </div>
  )
}
