import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import { activarEstudiante } from '../../../action/estudiante';
import { crearMatricula } from '../../../action/matricula';
import { libroDigitalTablaEstudiantes } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormMatriculaNueva = ({estudianteActivo, cursoActivo}) => {
    const dispatch = useDispatch();
    const [matricula, handleInputChange] = useForm({
        fechaMatricula:'', 
        curso: cursoActivo,
        estudiante: estudianteActivo,
    });

    const { fechaMatricula } = matricula;

    const handleCrear = (e) => {
        e.preventDefault()
        if( fechaMatricula === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearMatricula(matricula));
        dispatch(activarEstudiante(''))
        dispatch(libroDigitalTablaEstudiantes())
    }
    const handleCancelar = (e) => {
        e.preventDefault()
        dispatch(activarEstudiante(''))
        dispatch(libroDigitalTablaEstudiantes())
    }
    return (
        <form className='card'>
            <div className='card-body'>
                <h5>Formulario ingreso matricula</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="fechaMatricula" className="col-2 col-form-label">Fecha matricula</label>
                    <div className="col-4">
                        <input 
                            type="date" 
                            className="form-control" 
                            id="fechaMatricula"
                            name="fechaMatricula"
                            value={ fechaMatricula }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        onClick={ handleCrear }
                    >Crear</button>
                </div>
                <div className="col-4">
                    <button 
                        type="submit" 
                        className="btn btn-danger" 
                        onClick={ handleCancelar }
                    >Cancelar</button>
                </div>
            </div>
        </form>
    )
}
