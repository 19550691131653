import React from 'react'
import { useDispatch } from 'react-redux'
import { activarAnioEscolar } from '../../../action/anioEscolar'
import { activarCurso } from '../../../action/curso'
import { limpiarPeriodosEscolares } from '../../../action/periodoEscolar'
import { libroDigitalTablaCursos } from '../../../action/router'
import { RowPeriodoCurso } from './RowPeriodoCurso'

export const TablaPeriodosCurso = ({periodosEscolares}) => {
  const dispatch = useDispatch();

  const handleRegresar = () => {
    dispatch(activarCurso(""));
    dispatch(libroDigitalTablaCursos());
  };
  
  return (
    <div className='card'>
      <div className='card-body'>
        <h6>Tabla Periodos Escolares Curso</h6>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope="col">N°</th>
              <th scope="col">Periodo</th>
              <th scope="col">Año Escolar</th>
              <th scope="col">Establecimiento</th>
              <th scope="col">Asignaturas</th>
              <th scope="col">Talleres</th>
            </tr>
          </thead>
          <tbody>
            {periodosEscolares.length > 0 && periodosEscolares.map((e, index) =>(
              <RowPeriodoCurso
                key={e._id}
                periodoEscolar={e}
                index={index + 1}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <button className='btn btn-danger' onClick={handleRegresar}>Regresar</button>
              </td>
            </tr>
          </tfoot>
        </table>

      </div>
    </div>
  )
}
