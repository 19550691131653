import React from 'react'
import { useDispatch } from 'react-redux';
import { activarAnioEscolar } from '../../../action/anioEscolar';
import { limpiarCursos } from '../../../action/curso';
import { limpiarPeriodosEscolares } from '../../../action/periodoEscolar';
import { libroDigitalTablaAniosEscolares } from '../../../action/router';
import { RowCurso } from './RowCurso'

export const TablaCursos = ({cursos, aniosEscolares , docentes}) => {
  const dispatch=useDispatch();

  const handleRegresar = () => {
    dispatch(activarAnioEscolar(''));
    dispatch(limpiarPeriodosEscolares())
    dispatch(limpiarCursos())
    dispatch(libroDigitalTablaAniosEscolares())
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <h6>Tabla Cursos</h6>
        <table className='table table-bordered table-hover'>
            <thead>
              <tr>
                <th scope="col">N°</th>
                <th scope="col">Nombre</th>
                <th scope="col">Año Escolar</th>
                <th scope="col">Docente</th>
                <th scope="col">Matricula</th>
                <th scope="col">Estado</th>
                <th scope="col">Periodos</th>
                <th scope="col">Modificar</th>
              </tr>
            </thead>
            <tbody>
              {cursos.length > 0 && cursos.map((e, index) =>(
                <RowCurso 
                  key={e._id}
                  curso={e}
                  aniosEscolares={aniosEscolares}
                  docentes={docentes}
                  index={index + 1}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className='d-grid gap-2'>
                    <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                  </div>
                </td>
              </tr>
            </tfoot>
        </table>
      </div>
    </div>
  )
}
