import React from 'react'
import { useDispatch } from 'react-redux'
import { limpiarAniosEscolares } from '../../../action/anioEscolar'
import { activarEstablecimiento } from '../../../action/establecimiento'
import { libroDigitalTablaEstablecimientos } from '../../../action/router'
import { RowAnioEscolar } from './RowAnioEscolar'

export const TablaAniosEscolares = ({aniosEscolares, establecimientoActivo, establecimientos}) => {
  const dispatch = useDispatch()
  const establecimientoPrincipal = establecimientos.find(e => e._id === establecimientoActivo)
  const handleRegresar = () => {
    dispatch(activarEstablecimiento(""))
    dispatch(limpiarAniosEscolares())
    dispatch(libroDigitalTablaEstablecimientos())
  } 
  return (
    <div className='card'>
      <div className='card-body'>
        <h6>Tabla Años Escolares</h6>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope="col">N°</th>
              <th scope="col">Año Escolar</th>
              <th scope="col">Establecimiento</th>
              <th scope="col">Estado</th>
              <th scope="col">Cursos</th>
              <th scope="col">Periodos Escolares</th>
              <th scope="col">Modificar</th>
            </tr>
          </thead>
          <tbody>
            {aniosEscolares.length > 0 && aniosEscolares.map((e, index) =>(
              <RowAnioEscolar 
                key={e._id}
                anioEscolar={e}
                index={index + 1}
                establecimiento={establecimientoPrincipal}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <button className='btn btn-danger' onClick={handleRegresar}>Regresar</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}
