import { fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from 'sweetalert2';


export const crearMatricula = ( matricula ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('libroDigital/crearMatricula', matricula, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearMatriculaOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearMatriculaError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearMatriculaError());
        }
    }
}

const crearMatriculaOk = (e) => ({
    type: types.crearMatriculaOk,
    payload: e
});

const crearMatriculaError = () => ({ type: types.crearMatriculaError });


export const obtenerMatriculas = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchSinToken('libroDigital/obtenerMatriculas');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerMatriculasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerMatriculasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerMatriculasError());
        }
    }
}

export const obtenerMatriculasCurso = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerMatriculasCurso', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerMatriculasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerMatriculasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerMatriculasError());
        }
    }
}

export const obtenerMatriculasAnioEscolar = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('libroDigital/obtenerMatriculasAnioEscolar', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result } = body;
                dispatch(obtenerMatriculasOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(obtenerMatriculasError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerMatriculasError());
        }
    }
}

const obtenerMatriculasOk = (e) => ({ 
    type: types.obtenerMatriculasOk ,
    payload: e
});

const obtenerMatriculasError = () => ({ type: types.obtenerMatriculasError });



export const actualizarMatricula = (matricula) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken(`libroDigital/actualizarMatricula/${matricula._id}`, matricula, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const { result, message } = body;
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarMatriculaOk(result.payload));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 1000
                });
                dispatch(actualizarMatriculaError())
            }
        } catch (error) {
            console.log(error)
            dispatch(actualizarMatriculaError())
        }
    }
}

const actualizarMatriculaOk = (e) => ({ 
    type: types.actualizarMatriculaOk,
    payload: e
});

const actualizarMatriculaError = () => ({ type : types.actualizarMatriculaError });

export const activarMatricula = (e) => ({ 
    type: types.activarMatricula,
    payload: e
});

export const limpiarMatriculas = () => ({ 
    type: types.limpiarMatriculas
});
