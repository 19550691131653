import * as React from "react"
import { AdministradorLibroDigitalScreen } from "../components/administradorLibroDigital/AdministradorLibroDigitalScreen"
import Layout from '../components/layout'


const administradorLibroDigital = () => {
  return (
    <Layout>
      <div className="container-xl">
        <AdministradorLibroDigitalScreen  />
      </div>
    </Layout>
  )
}

export default administradorLibroDigital
