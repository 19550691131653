import React, { useEffect } from 'react'
import { navigate } from "gatsby";
import { TablaEstablecimientos } from './establecimiento/TablaEstablecimientos'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { obtenerEstablecimientos } from '../../action/establecimiento'
import { obtenerEstudiantes } from '../../action/estudiante'
import { TablaAniosEscolares } from './anioEscolar/TablaAniosEscolares'
import {  obtenerAniosEscolaresEstablecimiento } from '../../action/anioEscolar'
import { BotonNuevoAnioEscolar } from './anioEscolar/BotonNuevoAnioEscolar'
import { FormAnioEscolarNuevo } from './anioEscolar/FormAnioEscolarNuevo'
import { FormAnioEscolarActualizar } from './anioEscolar/FormAnioEscolarActualizar'
import { TablaPeriodosEscolares } from './periodoEscolar/TablaPeriodosEscolares'
import { BotonNuevoPeriodoEscolar } from './periodoEscolar/BotonNuevoPeriodoEscolar'
import { FormPeriodoEscolarNuevo } from './periodoEscolar/FormPeriodoEscolarNuevo'
import { obtenerPeriodosEscolaresAnioEscolar } from '../../action/periodoEscolar'
import { FormPeriodoEscolarActualizar } from './periodoEscolar/FormPeriodoEscolarActualizar'
import { TablaCursos } from './curso/TablaCursos'
import { BotonNuevoCurso } from './curso/BotonNuevoCurso'
import { obtenerCursosAnioEscolar } from '../../action/curso'
import { FormCursoNuevo } from './curso/FormCursoNuevo'
import { obtenerDocentesEstablecimiento } from '../../action/usuario'
import { FormCursoActualizar } from './curso/FormCursoActualizar'
import { TablaPeriodosCurso } from './curso/TablaPeriodosCurso'
import { TablaAsignaturas } from './asignatura/TablaAsignaturas'
import { limpiarAsignaturas, obtenerAsignaturasCurso, obtenerAsignaturasCursoPeriodoEscolar } from '../../action/asignatura'
import { BotonNuevaAsignatura } from './asignatura/BotonNuevaAsigntura'
import { FormAsignaturaNueva } from './asignatura/FormAsignaturaNueva'
import { FormAsignaturaActualizar } from './asignatura/FormAsignaturaActualizar'
import { TablaTalleres } from './taller/TablaTalleres'
import { limpiarTalleres, obtenerTalleresCursoPeriodoEscolar } from '../../action/talleres'
import { BotonNuevaTaller } from './taller/BotonNuevoTaller'
import { FormTallerNuevo } from './taller/FormTallerNuevo'
import { FormTallerActualizar } from './taller/FormTallerActualizar'
import { TablaMatriculasEstudiantes } from './matricula/TablaMatriculasEstudiantes'
import { limpiarMatriculas, obtenerMatriculasCurso } from '../../action/matricula'
import { FormMatriculaNueva } from './matricula/FormMatriculaNueva'


export const AdministradorLibroDigitalScreen = () => {
  const dispatch = useDispatch()
  const { uid } = useSelector( state => state.auth );
  const { establecimientos, establecimientoActivo } = useSelector( state => state.establecimiento );
  const { docentes } = useSelector( state => state.usuario );

  const { 
    estudiantes, 
    estudianteActivo, 
    matriculas, 
    matriculaActiva 
  } = useSelector( state => state.estudiante );

  const { 
    aniosEscolares, 
    anioEscolarActivo, 
    periodosEscolares, 
    periodoEscolarActivo,
    cursos,
    cursoActivo,
    asignaturas,
    asignaturaActiva,
    talleres,
    tallerActivo,
  } = useSelector( state => state.libroDigital )

  const {
    libroDigitalTablaEstablecimientos,
    libroDigitalTablaAniosEscolares,
    libroDigitalFormAnioEscolarNuevo,
    libroDigitalFormAnioEscolarActualizar,
    libroDigitalTablaPeriodosEscolares,
    libroDigitalFormPeriodoEscolarNuevo,
    libroDigitalFormPeriodoEscolarActualizar,
    libroDigitalTablaPeriodosCurso,
    libroDigitalTablaCursos,
    libroDigitalFormCursoNuevo,
    libroDigitalFormCursoActualizar,
    libroDigitalTablaAsignaturas,
    libroDigitalFormAsignaturaNueva,
    libroDigitalFormAsignaturaActualizar,
    libroDigitalTablaTalleres,
    libroDigitalFormTallerNuevo,
    libroDigitalFormTallerActualizar,
    libroDigitalTablaEstudiantes,
    libroDigitalTablaMatriculasEstudiantes,
    libroDigitalFormMatriculaNueva,
    libroDigitalFormMatriculaActualizar
  }= useSelector(state => state.router);

  useEffect(() => {
    const obtenerEstablecimientosPrincipal = () => dispatch(obtenerEstablecimientos());
    const obtenerEstudiantesPrincipal = () => dispatch(obtenerEstudiantes());
    obtenerEstablecimientosPrincipal()
    obtenerEstudiantesPrincipal()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(establecimientoActivo !=='' && establecimientoActivo !== null ){
      const filtro = {
        establecimiento: establecimientoActivo
      }
      const obtenerAniosEscolaresPrincipal = () => dispatch(obtenerAniosEscolaresEstablecimiento(filtro));
      const obtenerDocentesPrincipal = () => dispatch(obtenerDocentesEstablecimiento(filtro));
      obtenerAniosEscolaresPrincipal()
      obtenerDocentesPrincipal()
    }
    // eslint-disable-next-line
  }, [establecimientoActivo]);

  useEffect(() => {
    if(anioEscolarActivo !=='' && anioEscolarActivo !== null ){
      const filtro = {
        anioEscolar: anioEscolarActivo
      }
      const obtenerAniosEscolaresPrincipal = () => dispatch(obtenerPeriodosEscolaresAnioEscolar(filtro));
      const obtenerCursosPrincipal = () => dispatch(obtenerCursosAnioEscolar(filtro));
      obtenerAniosEscolaresPrincipal()
      obtenerCursosPrincipal()
    }
    // eslint-disable-next-line
  }, [anioEscolarActivo]);

  
  useEffect(() => {
    if(cursoActivo !=='' && cursoActivo !== null && periodoEscolarActivo !=='' && periodoEscolarActivo !== null ){
      const filtro = {
        curso: cursoActivo,
        periodoEscolar: periodoEscolarActivo
      }
      const obtenerAsignaturasCursoPrincipal = () => dispatch(obtenerAsignaturasCursoPeriodoEscolar(filtro));
      const obtenerTalleresCursoPrincipal = () => dispatch(obtenerTalleresCursoPeriodoEscolar(filtro));
      obtenerAsignaturasCursoPrincipal()
      obtenerTalleresCursoPrincipal()
    }else{
      dispatch(limpiarAsignaturas())
      dispatch(limpiarTalleres())
    }
    // eslint-disable-next-line
  }, [periodoEscolarActivo, cursoActivo]);
  
  useEffect(() => {
    if(cursoActivo !=='' && cursoActivo !== null){
      const filtro = {
        curso: cursoActivo,
      }
      const obtenerAsignaturasCursoPrincipal = () => dispatch(obtenerMatriculasCurso(filtro));
      obtenerAsignaturasCursoPrincipal()
    }else{
      dispatch(limpiarMatriculas())
    }
    // eslint-disable-next-line
  }, [cursoActivo]);
 
  if(!uid){
    setTimeout(() => {
      navigate("/login/")
    }, 1500);
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='h3 text-light'>Administrador Libro Digital</div>
      </div>
      <div className='col-12'>
        <div className='row'>
          { libroDigitalTablaAniosEscolares &&
            <div className='col-3'>
              <BotonNuevoAnioEscolar />
            </div>
          }
          { libroDigitalTablaPeriodosEscolares &&
            <div className='col-3'>
              <BotonNuevoPeriodoEscolar />
            </div>
          }
          { libroDigitalTablaCursos &&
            <div className='col-3'>
              <BotonNuevoCurso />
            </div>
          }
          { libroDigitalTablaAsignaturas &&
            <div className='col-3'>
              <BotonNuevaAsignatura />
            </div>
          }
          { libroDigitalTablaTalleres &&
            <div className='col-3'>
              <BotonNuevaTaller />
            </div>
          }
        </div>
      </div>
      <div className='col-12'>
        {libroDigitalTablaEstablecimientos && 
          <TablaEstablecimientos 
            establecimientos={establecimientos} 
          />
        }
        
        {libroDigitalTablaAniosEscolares &&
          <TablaAniosEscolares 
            aniosEscolares={aniosEscolares}
            establecimientoActivo={establecimientoActivo}
            establecimientos={establecimientos}
          />
        }
        {libroDigitalFormAnioEscolarNuevo &&
          <FormAnioEscolarNuevo 
            establecimientoActivo={establecimientoActivo} 
          />
        }
        {libroDigitalFormAnioEscolarActualizar &&
          <FormAnioEscolarActualizar 
            anioEscolarActivo={anioEscolarActivo} 
            aniosEscolares={aniosEscolares} 
          />
        }
        {libroDigitalTablaPeriodosEscolares &&
          <TablaPeriodosEscolares 
            periodosEscolares={periodosEscolares}
          />
        }
        {libroDigitalFormPeriodoEscolarNuevo &&
          <FormPeriodoEscolarNuevo
            anioEscolarActivo={anioEscolarActivo} 
            establecimientoActivo={establecimientoActivo}
          />
        }
        {libroDigitalFormPeriodoEscolarActualizar &&
          <FormPeriodoEscolarActualizar 
            periodoEscolarActivo={periodoEscolarActivo}
            periodosEscolares={periodosEscolares}
          />
        }
        {libroDigitalTablaCursos &&
          <TablaCursos cursos={cursos} aniosEscolares={aniosEscolares} docentes={docentes} />
        }
        {libroDigitalFormCursoNuevo &&
          <FormCursoNuevo anioEscolarActivo={anioEscolarActivo} docentes={docentes} />
        }
        
        {libroDigitalFormCursoActualizar &&
          <FormCursoActualizar cursoActivo={cursoActivo} cursos={cursos} docentes={docentes} />
        }
        {libroDigitalTablaEstudiantes && 
          <TablaMatriculasEstudiantes 
            estudiantes={estudiantes}
            matriculas={matriculas}

          />
        }
        {libroDigitalFormMatriculaNueva &&
          <FormMatriculaNueva 
            estudianteActivo={estudianteActivo}
            cursoActivo={cursoActivo}
          />
        }
        {libroDigitalTablaPeriodosCurso &&
          <TablaPeriodosCurso periodosEscolares={periodosEscolares} cursoActivo={cursoActivo} cursos={cursos} aniosEscolares={aniosEscolares} />
        }
        {libroDigitalTablaAsignaturas &&
          <TablaAsignaturas 
            asignaturas={asignaturas} 
            cursos={cursos} 
            periodosEscolares={periodosEscolares} 
            docentes={docentes}
          />
        }
        {libroDigitalFormAsignaturaNueva &&
          <FormAsignaturaNueva 
            cursoActivo={cursoActivo} 
            periodoEscolarActivo={periodoEscolarActivo}
            docentes={docentes}
          />
        }
        {libroDigitalFormAsignaturaActualizar &&
          <FormAsignaturaActualizar 
            asignaturaActiva={asignaturaActiva}
            asignaturas={asignaturas}
            docentes={docentes}
          
          />
        }
        {libroDigitalTablaTalleres && 
          <TablaTalleres 
            talleres={talleres}
            cursos={cursos}
            periodosEscolares={periodosEscolares} 
            docentes={docentes}
          />
        }
        {libroDigitalFormTallerNuevo &&
          <FormTallerNuevo 
            cursoActivo={cursoActivo}
            periodoEscolarActivo={periodoEscolarActivo}
            docentes={docentes}
          />
        }
        {libroDigitalFormTallerActualizar && 
          <FormTallerActualizar 
            tallerActivo={tallerActivo}
            talleres={talleres}
            docentes={docentes}
          />
        }
       
        {/* 
        <AdministradorLibroDigitalCursoScreen />
        <AdministradorLibroDigitalMatriculaScreen />
        <AdministradorLibroDigitalAsignaturaScreen />
        <AdministradorLibroDigitalTallerScreen />
        <AdministradorLibroDigitalPostulanteScreen /> */}
      </div>
    </div>
  )
}
