import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import { crearPeriodoEscolar } from '../../../action/periodoEscolar';
import { libroDigitalTablaPeriodosEscolares } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormPeriodoEscolarNuevo = ({anioEscolarActivo, establecimientoActivo}) => {
    const dispatch= useDispatch();
    const [periodoPrincipal, handleInputChange] = useForm({
        periodo: '',
        anioEscolar: anioEscolarActivo,
        establecimiento: establecimientoActivo
    })
    const {periodo} = periodoPrincipal;

    const handleCrear = (e) => {
        e.preventDefault()
        if(periodo === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearPeriodoEscolar(periodoPrincipal));
        dispatch(libroDigitalTablaPeriodosEscolares())
    }

    const handleCancelar = (e) => {
        e.preventDefault()
        dispatch(libroDigitalTablaPeriodosEscolares())
    }


    return (
        <div className='card'>
            <div className='card-body'>
                <h5>Formulario ingreso periodo escolar</h5>
                <form>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="periodo" className="col-2 col-form-label">Periodo</label>
                        <div className="col-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="periodo"
                                name="periodo"
                                value={ periodo }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-primary" 
                                onClick={ handleCrear }
                            >Crear Periodo</button>
                        </div>
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-danger" 
                                onClick={ handleCancelar }
                            >Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
