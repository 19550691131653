import React from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import { crearAnioEscolar } from '../../../action/anioEscolar';
import { libroDigitalTablaAniosEscolares } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormAnioEscolarNuevo = ({establecimientoActivo}) => {
    const dispatch = useDispatch();
    const [anio, handleInputChange] = useForm({
        anioEscolar: '',
        establecimiento: establecimientoActivo,
    });
    const { anioEscolar } = anio;

    const handleEnviar = (e) => {
        e.preventDefault();
        if( anioEscolar === ''){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearAnioEscolar(anio))
        dispatch(libroDigitalTablaAniosEscolares())
    }

    const handleCancelar = (e) => {
        e.preventDefault();
        dispatch(libroDigitalTablaAniosEscolares())
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <h5>Formulario ingreso año escolar</h5>
                <form>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="anioEscolar" className="col-2 col-form-label">Año escolar</label>
                        <div className="col-4">
                            <input 
                                type="date" 
                                className="form-control" 
                                id="anioEscolar"
                                name="anioEscolar"
                                value={ anioEscolar }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-primary" 
                                onClick={ handleEnviar }
                            >Crear</button>
                        </div>
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-danger" 
                                onClick={ handleCancelar }
                            >Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
