import React from 'react'
import { useDispatch } from 'react-redux'
import { libroDigitalFormPeriodoEscolarNuevo } from '../../../action/router'

export const BotonNuevoPeriodoEscolar = () => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch(libroDigitalFormPeriodoEscolarNuevo())
    }
    return (
        <div className='d-grid gap-2'>
            <button className='btn btn-primary btn-sm' onClick={handleClick}>Nuevo Periodo Escolar</button>
        </div>
    )
}
