import React from 'react'
import { useDispatch } from 'react-redux'
import { libroDigitalTablaCursos } from '../../../action/router'
import { RowMatriculasEstudiante } from './RowMatriculasEstudiante'

export const TablaMatriculasEstudiantes = ({estudiantes, matriculas}) => {
  const dispatch = useDispatch()

  const handleRegresar = () => {
    dispatch(libroDigitalTablaCursos())
  }
  
  return (
    <div className='card'>
      <div className='card-body'>
        <h6>Tabla Matriculas Estudiantes</h6>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope="col">N°</th>
              <th scope="col">Estudiante</th>
              <th scope="col">Fecha nacimiento</th>
              <th scope="col">Estado</th>
              <th scope="col">Matricula</th>
            </tr>
          </thead>
          <tbody>
            {estudiantes.length > 0 && estudiantes.map((e, index) =>(
              <RowMatriculasEstudiante
                key={e._id}
                estudiante={e}
                matriculas={matriculas}
                index={index + 1}
              />
            ))}
          </tbody>
          <tfoot>
              <tr>
                <td>
                  <div className='d-grid gap-2'>
                    <button className='btn btn-danger btn-sm' onClick={handleRegresar} >Regresar</button>
                  </div>
                </td>
              </tr>
            </tfoot>
        </table>
      </div>
    </div>
  )
}
