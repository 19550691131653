import React from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { activarAsignatura, actualizarAsignatura } from '../../../action/asignatura';
import { libroDigitalTablaAsignaturas } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormAsignaturaActualizar = ({asignaturaActiva, asignaturas, docentes}) => {
    const dispatch=useDispatch();
    const asignaturaPrincipal=asignaturas.find(e=>e._id===asignaturaActiva);
    const [asignatura, handleInputChange] =useForm(asignaturaPrincipal);
    const {nombre, cantidadHoras, docente} =asignatura;

    const handleActualizar = (e) => {
        e.preventDefault();
        if( nombre === '' || cantidadHoras==='' || docente===''  ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(actualizarAsignatura(asignatura));
        dispatch(activarAsignatura(''));
        dispatch(libroDigitalTablaAsignaturas())
    }

    const handleCancelar = (e) => {
        e.preventDefault();
        dispatch(activarAsignatura(''));
        dispatch(libroDigitalTablaAsignaturas())
    }

    return (
        <form className='card'>
            <div className='card-body'>
                <h5>Formulario actualizar asignatura</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="nombre" className="col-2 col-form-label">Nombre</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="nombre"
                            name="nombre"
                            value={ nombre }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <label htmlFor="cantidadHoras" className="col-2 col-form-label">Cantidad de horas</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="cantidadHoras"
                            name="cantidadHoras"
                            value={ cantidadHoras }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="docente" className="col-2 col-form-label">Docente</label>
                    <select 
                        className="form-select" 
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={docente}  
                        name="docente" 
                    >
                        <option value="" defaultValue>Seleccione...</option>
                        {docentes && docentes.map(e=>(
                            <option value={e._id} key={e._id} >{e.nombres} {e.apellidoPaterno} {e.apellidoMaterno}</option>
                        ))}
                        
                    </select>
                </div>
                <div className="row mb-3 mt-3">
                    <div className="col-4">
                        <button 
                            type="submit" 
                            className="btn btn-primary" 
                            onClick={ handleActualizar }
                        >Actualizar</button>
                    </div>
                    <div className="col-4">
                        <button 
                            type="submit" 
                            className="btn btn-danger" 
                            onClick={ handleCancelar }
                        >Cancelar</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
