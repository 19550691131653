import React from 'react'
import { useDispatch } from 'react-redux';
import { activarAsignatura, actualizarAsignatura } from '../../../action/asignatura';
import { libroDigitalFormAsignaturaActualizar } from '../../../action/router';

export const RowAsignaturas = ({asignatura, cursos, periodosEscolares, docentes, index}) => {
  const dispatch= useDispatch();
  const { nombre, estado , cantidadHoras, docente, curso, periodoEscolar } = asignatura;
  const periodoPrincipal= periodosEscolares.find(e=>e._id === periodoEscolar);
  const cursoPrincipal= cursos.find(e=>e._id === curso);
  const docentePrincipal= docentes.find(e=>e._id === docente);

  const handleActivar = () => {
    asignatura.estado=true;
    dispatch(actualizarAsignatura(asignatura))
  }

  const handleDesactivar = () => {
    asignatura.estado=false;
    dispatch(actualizarAsignatura(asignatura))
  }
  

  const handleActualizar = () => {
    dispatch(activarAsignatura(asignatura._id))
    dispatch(libroDigitalFormAsignaturaActualizar())
  }

  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{nombre}</td>
      <td>{cantidadHoras}</td>
      <td>{docentePrincipal.nombres} {docentePrincipal.apellidoPaterno} {docentePrincipal.apellidoMaterno} </td>
      <td>{cursoPrincipal.nombre}</td>
      <td>{periodoPrincipal.periodo}</td>
      <td>
        {estado 
          ? <div className='d-grid gap-2'><button className='btn btn-success btn-sm' onClick={handleDesactivar} >Activo</button></div>
          : <div className='d-grid gap-2'><button className='btn btn-danger btn-sm' onClick={handleActivar} >Inactivo</button></div>
        }
      </td>
      <td><div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={handleActualizar} >Modificar</button></div></td>
    </tr>
  )
}
