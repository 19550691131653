import React from 'react'
import { useDispatch } from 'react-redux';
import { activarAnioEscolar, actualizarAnioEscolar } from '../../../action/anioEscolar';
import { libroDigitalTablaAniosEscolares } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormAnioEscolarActualizar = ({anioEscolarActivo, aniosEscolares}) => {
    const dispatch = useDispatch();
    const anioEscolarPrincipal = aniosEscolares.find( e => e._id === anioEscolarActivo);
    const [anio, handleInputChange] = useForm(anioEscolarPrincipal);
    const { anioEscolar } = anio;
    const handleActualizar = (e) =>{
        e.preventDefault()
        dispatch(actualizarAnioEscolar(anio));
        dispatch(activarAnioEscolar(''));
        dispatch(libroDigitalTablaAniosEscolares())
    }
    const handleCancelar = (e) =>{
        e.preventDefault()
        dispatch(activarAnioEscolar(''));
        dispatch(libroDigitalTablaAniosEscolares())
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <h5>Formulario actualizar año escolar</h5>
                <form>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="anioEscolar" className="col-2 col-form-label">Año escolar</label>
                        <div className="col-4">
                            <input 
                                type="date" 
                                className="form-control" 
                                id="anioEscolar"
                                name="anioEscolar"
                                value={ anioEscolar }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-primary" 
                                onClick={ handleActualizar }
                            >Actualizar</button>
                        </div>
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-danger" 
                                onClick={ handleCancelar }
                            >Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
